import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const CustomModal = ({ show, onHide, children, fit }) => {
  const customStyles = {
    overlay: {
      zIndex: "9000",
      background: "#00000050",
    },
    content: fit
      ? {
          width: "fit-content",
          height: "fit-content",
          inset: "50%",
          transform: "translate(-50%,-50%)",
        }
      : {},
  };
  return (
    <Modal style={customStyles} isOpen={show} onRequestClose={onHide}>
      <div className="max-h-[90vh] overflow-y-scroll" dir="rtl">{children}</div>
    </Modal>
  );
};

export default CustomModal;
