import React, { useContext, useEffect, useState } from "react";
import { httpReauest } from "../../../utils/httpRequest";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import Loading from "../Loading";

const EditDealerData = () => {
  const [loading, setloading] = useState();

  async function getData() {
    setloading(true);
    const res = await httpReauest("GET", "/data/info", {}, {});
    formik.setFieldValue("card_number", res.data?.data.card_number);
    formik.setFieldValue("card_name", res.data?.data.card_name);
    setloading(false);
  }
  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      card_number: "",
      card_name: "",
    },
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));

      await httpReauest("POST", "/data", values, {
        "x-access-token": user.token,
      })
        .then((dat) => {
          if (dat?.status == 201) {
            toast.success("موفقیت آمیز");
            setloading(false);
            formik.resetForm();
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر دسته بندی</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            id="card_number"
            name="card_number"
            value={formik.values.card_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
            placeholder="6063-7311-7748-2511"
          />
          <p className="on-border font-bold">شماره کارت واریز*</p>
        </span>
        <span className="relative">
          <input
            id="card_name"
            name="card_name"
            value={formik.values.card_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
            placeholder="مثلا : سعید امیری"
          />
          <p className="on-border font-bold">نام صاحب کارت*</p>
        </span>
        <span>
          <button type="submit" className="btn-brand">
            ارسال
          </button>
        </span>
      </form>
    </div>
  );
};

export default EditDealerData;
