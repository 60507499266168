import { io } from "socket.io-client";
import { BASE_URL, CDN_BASE_URL } from "../config";
import { isLoggedIn } from "./authHelper";

export let socket;

export const initiateSocketConnection = () => {
  socket = io(CDN_BASE_URL, {
    autoConnect: false,
    auth: {
      admin: true,
    },
  });
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

// export async function getIp() {
//   const { data } = await axios.get("https://api.ipify.org/?format=json");
//   return data?.ip;
// }
