import React, { useEffect, useState } from "react";
import Loading from "../components/common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { httpReauest } from "../utils/httpRequest";
import { Shamsi } from "basic-shamsi";
import { CDN_BASE_URL } from "../config";
import CustomModal from "../components/common/Modal";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { priceComma } from "../utils/priceComma";
import moment from "moment";

const OrdersPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState();
  const [hasmore, sethasmore] = useState(true);
  const [show, setShow] = useState(false);
  const [selected, setselcted] = useState();
  const [step, setstep] = useState(1);
  const [postCode, setpostCode] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setselcted(data);
    setShow(true);
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "GET",
      "/user",
      {},
      { "x-access-token": user.token }
    ).then(({ data }) => {
      setProducts(data.data);
    });
    setLoading(false);
  }

  return (
    <div>
      <div className="w-full">
        <div className=" mb-10">
          <h1 className="title">Users ({products.length})</h1>
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;
