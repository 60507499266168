import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { FaTrash } from "react-icons/fa6";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";

const CreateSubProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [categorys, setCategorys] = useState();
  const [loading, setloading] = useState();
  const [previewCatalog, setpreviewCatalog] = useState();
  const [catalog, setCatalog] = useState();
  const [previewVideo, setPreviewVideo] = useState();
  const [video, setVideo] = useState();

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      weight: Yup.string().required("الزامی است"),
      makingFee: Yup.string().required("الزامی است"),
      balance: Yup.string().required("الزامی است"),
      product: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      weight: "",
      makingFee: "",
      balance: "",
      product: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));

      await httpReauest("POST", "/product/sub", values, {
        "x-access-token": user.token,
      })
        .then((dat) => {
          if (dat?.status == 201) {
            toast.success("زیر محصول ساخته شد");
            setloading(false);
            formik.resetForm();
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await httpReauest("GET", "/product/names/dash", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد زیر محصول</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="product"
            name="product"
            value={formik.values.product}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={` w-full form-brand h-[48px]`}
          >
            <option></option>
            {categorys?.map((e) => (
              <option value={e._id}>{e.name}</option>
            ))}
          </select>
          {formik.errors.product && formik.touched.product && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.product}
            </small>
          )}
          <p className="on-border font-bold">محصول مرتبط*</p>
        </span>
        <span className="relative">
          <input
            placeholder="مثلا : 1.350"
            id="weight"
            name="weight"
            value={formik.values.weight}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.weight && formik.touched.weight && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.weight}
            </small>
          )}
          <p className="absolute translate-y-[-50%] top-[24px] left-4">gr</p>
          <p className="on-border font-bold">وزن*</p>
        </span>
        <span className="relative">
          <input
            placeholder="مثلا : 20"
            id="makingFee"
            name="makingFee"
            value={formik.values.makingFee}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.makingFee && formik.touched.makingFee && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.makingFee}
            </small>
          )}
          <p className="on-border font-bold">اجرت*</p>
        </span>
        <span className="relative">
          <input
            placeholder="مثلا : 10"
            id="balance"
            name="balance"
            value={formik.values.balance}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.balance && formik.touched.balance && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.balance}
            </small>
          )}
          <p className="on-border font-bold">تعداد*</p>
        </span>

        {loading ? (
          <Loading />
        ) : (
          <button type="submit" className="btn-brand">
            ارسال
          </button>
        )}
      </form>
    </div>
  );
};

export default CreateSubProductForm;
